/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "am-i-paying-the-right-price-for-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#am-i-paying-the-right-price-for-hearing-aids",
    "aria-label": "am i paying the right price for hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Am I Paying the Right Price for Hearing Aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the ", React.createElement("a", {
    href: "https://www.hearingloss.org/wp-content/uploads/HLAA_HearingLoss_Facts_Statistics.pdf",
    target: "_blank"
  }, "Hearing Loss Association of America"), ", around 48 million Americans suffer from some degree of hearing loss. Looking for hearing aids can be a time-consuming and stressful process. You might not know which type is right for you and whether you’re getting a fair price."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, how much are hearing aids? It depends on the brand, quality, and features. Basic hearing aids are cost-effective but won’t contain the technological advances newer hearing aids have. Cheaper hearing aids also don’t allow you to customize the settings to meet your specific needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You should explore the different types available and determine whether improving your hearing is worth the cost. After all, hearing aids are something you’ll wear every day. They should provide the functionality necessary to participate in conversations and activities without struggling to hear sounds and understand what people say to you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-much-do-hearing-aids-cost",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-much-do-hearing-aids-cost",
    "aria-label": "how much do hearing aids cost permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Much Do Hearing Aids Cost?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The total price of a pair of hearing aids includes more than the cost of the device. Your hearing aids might come with various services, such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing tests and exams"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Follow-up appointments"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fitting costs"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Routine cleanings"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Batteries or chargers"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Warranties"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Although the final number might seem expensive, it's worth it considering how often you'll use your hearing aids. Most people wear them every single waking moment of the day. That's around 12 to 16 hours. Basically, you’re spending the same amount of money each day as your morning cup of coffee."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-these-hearing-aids-really-worth-the-price",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-these-hearing-aids-really-worth-the-price",
    "aria-label": "are these hearing aids really worth the price permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are These Hearing Aids Really Worth the Price?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Knowing whether you’re paying the right price for hearing aids depends on how much hearing is worth to you. Hearing aids allow wearers to hear specific frequencies they struggle to hear under normal circumstances."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can adjust the settings on your device to understand speech, hear yourself talk, and drown out background noise during conversations. The hearing aids you choose can also come with certain technologies and features customized to improve your type of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Common features included in newer hearing aids include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "Bluetooth connectivity"), " – You can connect to Bluetooth and stream your favorite music, TV shows, and podcasts directly to your hearing aids. You can also take phone calls while wearing the device."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable",
    className: "c-md-a"
  }, "Rechargeable batteries"), " – Instead of fumbling around in your pockets or purse for batteries, use a portable charging case. It’s convenient and allows you to charge your hearing aids without disrupting your busy schedule."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/phone-connectivity",
    className: "c-md-a"
  }, "Smartphone app"), " – Technological advances allow wearers to control their hearing aids with their smartphones. You can easily change the volume or program your device based on your environment. For example, you can adjust the level of background noise while eating at a crowded restaurant so you can comfortably communicate with others."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech clarity – Speech clarity is a serious problem for people with hearing loss. Features in medical-grade hearing aids incorporate echo adjustments, spatial configurators, and narrow directionality for better speech clarity in various types of environments."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "finding-the-most-effective-hearing-aids-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#finding-the-most-effective-hearing-aids-for-you",
    "aria-label": "finding the most effective hearing aids for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Finding the Most Effective Hearing Aids for You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids could improve your hearing-related condition. You should undergo a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "comprehensive hearing test"), " to determine your level of hearing loss. Hearing aids aren’t a one size fits all solution. You need a device programmed to resolve your specific issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your audiologist can help you choose a device with the appropriate customized settings and show you how to use it. Your hearing aids should include the initial exam and fitting, follow-up adjustments, device support, and other necessary services. You shouldn’t pay additional costs to achieve your desired results."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You don't have to live the rest of your life struggling to carry on conversations with others or feeling embarrassed by bulky hearing aids. Hear.com offers a range of hearing aids with a nearly invisible design and life-changing features."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "speak-to-an-experienced-audiologist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speak-to-an-experienced-audiologist-from-hearcom",
    "aria-label": "speak to an experienced audiologist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speak to an Experienced Audiologist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we believe in providing high-quality hearing aids and professional services to our customers. We offer a no-risk trial, so you can test a pair of hearing aids and determine if they work for you. ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire",
    className: "c-md-a"
  }, "Take our questionnaire"), " to find out if you qualify."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
